import React, { useEffect, useState } from "react";
import { adjustBrightness } from "../mgt/constants";

const primaryColor = '#00A3BF';
const secondaryColor = '#172B4D';
const backgroundColor = '#1d2125';
const registerTextColor = 'rgb(235, 241, 243)'

export const RevealIcon = ({ size = 24, transparent = false, grey = false }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        fill='none'
        viewBox='-2.4 -2.4 28.8 28.8'
    >
        {!transparent && (
            <rect
                width='28.8'
                height='28.8'
                x='-2.4'
                y='-2.4'
                fill={registerTextColor}
                strokeWidth='0'
                rx='14.4'
            />
        )}
        <g fillRule='evenodd' clipRule='evenodd'>
            <path
                fill={!grey ? registerTextColor : 'rgba(0, 0, 0, 0.4)'}
                d='M16.239 5.001A9.479 9.479 0 003.872 8.603L2.655 10.63a2.66 2.66 0 000 2.738l1.217 2.028a9.479 9.479 0 0016.256 0l1.217-2.028a2.66 2.66 0 000-2.738l-1.217-2.028A9.479 9.479 0 0016.238 5zm-7.584 1.79a7.478 7.478 0 019.758 2.84l1.217 2.03a.66.66 0 010 .679l-1.217 2.028a7.477 7.477 0 01-12.826 0L4.37 12.34a.66.66 0 010-.68l1.217-2.028A7.479 7.479 0 018.655 6.79z'
            ></path>
            <path
                fill={!grey ? registerTextColor : 'rgba(0, 0, 0, 0.4)'}
                d='M12 8a4 4 0 100 7.999A4 4 0 0012 8zm-2 4a2 2 0 113.999-.002 2 2 0 01-3.999.003z'
            ></path>
        </g>
    </svg>
)

export const GoogleIcon = ({ size = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-0.5 0 48 48"
    >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-401 -860)">
                <g transform="translate(401 860)">
                    <path
                        fill="#FBBC05"
                        d="M9.827 24c0-1.524.253-2.986.705-4.356l-7.909-6.04A23.456 23.456 0 00.213 24c0 3.737.868 7.26 2.407 10.388l7.905-6.05A13.885 13.885 0 019.827 24"
                    ></path>
                    <path
                        fill="#EB4335"
                        d="M23.714 10.133c3.311 0 6.302 1.174 8.652 3.094L39.202 6.4C35.036 2.773 29.695.533 23.714.533a23.43 23.43 0 00-21.09 13.071l7.908 6.04a13.849 13.849 0 0113.182-9.51"
                    ></path>
                    <path
                        fill="#34A853"
                        d="M23.714 37.867a13.849 13.849 0 01-13.182-9.51l-7.909 6.038a23.43 23.43 0 0021.09 13.072c5.732 0 11.205-2.036 15.312-5.849l-7.507-5.804c-2.118 1.335-4.786 2.053-7.804 2.053"
                    ></path>
                    <path
                        fill="#4285F4"
                        d="M46.145 24c0-1.387-.213-2.88-.534-4.267H23.714V28.8h12.604c-.63 3.091-2.346 5.468-4.8 7.014l7.507 5.804c4.314-4.004 7.12-9.969 7.12-17.618"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
)

export const StatusActiveIcon = ({ size = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        version="1.1"
        viewBox="0 0 345 345"
        xmlSpace="preserve"
    >
        <circle cx="172.5" cy="172.5" r="172.5" fill="#1D912F"></circle>
        <circle cx="172.5" cy="172.5" r="157.5" fill="#29CB41"></circle>
    </svg>
)

export const StatusPausedIcon = ({ size = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        version="1.1"
        viewBox="0 0 345 345"
        xmlSpace="preserve"
    >
        <circle cx="172.5" cy="172.5" r="172.5" fill="#D19B26"></circle>
        <circle cx="172.5" cy="172.5" r="157.5" fill="#FFBD2E"></circle>
    </svg>
)

export const StatusInactiveIcon = ({ size = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        version="1.1"
        viewBox="0 0 345 345"
        xmlSpace="preserve"
    >
        <circle cx="172.5" cy="172.5" r="172.5" fill="#E1473F"></circle>
        <circle cx="172.5" cy="172.5" r="157.5" fill="#FF5F57"></circle>
    </svg>
)

export const PlayIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M7.238 3.044C5.652 2.182 3.75 3.32 3.75 5.033v13.934c0 1.714 1.902 2.851 3.488 1.989l12.812-6.967c1.6-.87 1.6-3.108 0-3.977L7.238 3.044zM2.25 5.033c0-2.905 3.167-4.687 5.705-3.306l12.812 6.967c2.644 1.438 2.644 5.174 0 6.612L7.955 22.273c-2.538 1.38-5.705-.4-5.705-3.306V5.033z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const PauseIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M5.948 1.25h.104c.898 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243v12.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.725 1.65-.456.456-1.023.642-1.65.726-.595.08-1.345.08-2.243.08h-.104c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V5.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08zM3.905 2.817c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C2.753 4.388 2.75 5.036 2.75 6v12c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095V6c0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3C7.612 2.753 6.964 2.75 6 2.75c-.964 0-1.612.002-2.095.067zM17.948 1.25h.104c.899 0 1.648 0 2.242.08.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243v12.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.726 1.65-.455.456-1.022.642-1.65.726-.594.08-1.343.08-2.242.08h-.104c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V5.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.726-1.65.455-.456 1.022-.642 1.65-.726.594-.08 1.343-.08 2.242-.08zm-2.043 1.567c-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788-.064.483-.066 1.131-.066 2.095v12c0 .964.002 1.612.066 2.095.063.461.17.659.3.789.13.13.328.237.79.3.482.064 1.13.066 2.094.066s1.612-.002 2.095-.067c.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095V6c0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066-.964 0-1.612.002-2.095.067z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const DownloadIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            d="M12.553 16.506a.75.75 0 01-1.107 0l-4-4.375a.75.75 0 011.108-1.012l2.696 2.95V3a.75.75 0 011.5 0v11.068l2.697-2.95a.75.75 0 111.107 1.013l-4 4.375z"
        ></path>
        <path
            fill={color}
            d="M3.75 15a.75.75 0 00-1.5 0v.055c0 1.367 0 2.47.117 3.337.12.9.38 1.658.981 2.26.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116h6.11c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337V15a.75.75 0 00-1.5 0c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.755.101-1.756.103-3.191.103H9c-1.435 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191z"
        ></path>
    </svg>
)

export const DownArrowsIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M4.43 6.512a.75.75 0 011.058-.081L12 12.012l6.512-5.581a.75.75 0 01.976 1.138l-7 6a.75.75 0 01-.976 0l-7-6a.75.75 0 01-.081-1.057zm0 4a.75.75 0 011.058-.081L12 16.012l6.512-5.581a.75.75 0 11.976 1.139l-7 6a.75.75 0 01-.976 0l-7-6a.75.75 0 01-.081-1.058z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const RefreshIcon = ({ size = 24, color = '#9FADBC', customClass = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        className={customClass}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M2.93 11.2c.072-4.96 4.146-8.95 9.149-8.95a9.158 9.158 0 017.814 4.357.75.75 0 01-1.277.786 7.658 7.658 0 00-6.537-3.643c-4.185 0-7.575 3.328-7.648 7.448l.4-.397a.75.75 0 011.057 1.065l-1.68 1.666a.75.75 0 01-1.056 0l-1.68-1.666A.75.75 0 112.528 10.8l.403.4zm16.856-.733a.75.75 0 011.055 0l1.686 1.666a.75.75 0 11-1.054 1.067l-.41-.405c-.07 4.965-4.161 8.955-9.18 8.955a9.197 9.197 0 01-7.842-4.356.75.75 0 111.277-.788 7.697 7.697 0 006.565 3.644c4.206 0 7.61-3.333 7.68-7.453l-.408.403a.75.75 0 11-1.055-1.067l1.686-1.666z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const LogOutIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        transform="rotate(180)"
        viewBox="0 0 24 24"
    >
        <g fill={color}>
            <path d="M14.945 1.25c-1.367 0-2.47 0-3.337.117-.9.12-1.658.38-2.26.981-.524.525-.79 1.17-.929 1.928-.135.737-.161 1.638-.167 2.72a.75.75 0 001.5.008c.006-1.093.034-1.868.142-2.457.105-.566.272-.895.515-1.138.277-.277.666-.457 1.4-.556.755-.101 1.756-.103 3.191-.103h1c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v8c0 1.435-.001 2.436-.103 3.192-.099.734-.279 1.122-.556 1.399-.277.277-.665.457-1.399.556-.755.101-1.756.103-3.192.103h-1c-1.435 0-2.436-.002-3.192-.103-.733-.099-1.122-.28-1.399-.556-.243-.244-.41-.572-.515-1.138-.108-.589-.136-1.364-.142-2.457a.75.75 0 10-1.5.008c.006 1.082.032 1.983.167 2.72.14.758.405 1.403.93 1.928.601.602 1.36.86 2.26.982.866.116 1.969.116 3.336.116h1.11c1.368 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-8.11c0-1.367 0-2.47-.116-3.337-.121-.9-.38-1.658-.982-2.26-.602-.602-1.36-.86-2.26-.981-.867-.117-1.97-.117-3.337-.117h-1.11z"></path>
            <path d="M15 11.25a.75.75 0 010 1.5H4.027l1.961 1.68a.75.75 0 11-.976 1.14l-3.5-3a.75.75 0 010-1.14l3.5-3a.75.75 0 11.976 1.14l-1.96 1.68H15z"></path>
        </g>
    </svg>
)

export const RightArrowIcon = ({ size = 24, color = '#9FADBC', rotation = 0 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        style={{ transform: `rotate(${rotation}deg)` }}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M8.512 4.43a.75.75 0 011.057.082l6 7a.75.75 0 010 .976l-6 7a.75.75 0 01-1.138-.976L14.012 12 8.431 5.488a.75.75 0 01.08-1.057z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const RightArrowsIcon = ({ size = 24, color = '#9FADBC', rotation = 0 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        style={{ transform: `rotate(${rotation}deg)` }}
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M6.512 4.43a.75.75 0 011.057.082l6 7a.75.75 0 010 .976l-6 7a.75.75 0 01-1.138-.976L12.012 12 6.431 5.488a.75.75 0 01.08-1.057zm4 0a.75.75 0 011.058.082l6 7a.75.75 0 010 .976l-6 7a.75.75 0 01-1.14-.976L16.013 12l-5.581-6.512a.75.75 0 01.081-1.057z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const EditIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M14.757 2.621a4.682 4.682 0 016.622 6.622l-9.486 9.486c-.542.542-.86.86-1.216 1.137-.418.327-.87.607-1.35.835-.406.193-.834.336-1.56.578l-3.332 1.11-.802.268a1.81 1.81 0 01-2.29-2.29l1.378-4.133c.242-.727.385-1.155.578-1.562.229-.479.508-.931.835-1.35.276-.354.595-.673 1.137-1.215l9.486-9.486zM4.4 20.821l2.841-.948c.791-.264 1.127-.377 1.44-.526.38-.182.74-.404 1.073-.663.273-.214.525-.463 1.115-1.053l7.57-7.57a7.36 7.36 0 01-2.757-1.744A7.358 7.358 0 0113.94 5.56l-7.57 7.57c-.59.589-.84.84-1.053 1.114-.259.333-.481.692-.663 1.073-.149.313-.262.649-.526 1.44l-.947 2.841L4.4 20.82zM15.155 4.343c.035.175.092.413.189.69a5.857 5.857 0 001.4 2.222 5.857 5.857 0 002.221 1.4c.278.097.516.154.691.189l.662-.662a3.182 3.182 0 00-4.5-4.5l-.663.661z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const PlusCircleIcon = ({ size = 24, color = '#9FADBC', rotate = 0, onClick = () => { }, title = '', style = {} }) => (
    <div
        title={title}
        onClick={onClick}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: onClick ? 'pointer' : 'default',
            ...style
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
            transform={`rotate(${rotate})`}
        >
            <path
                fill={color}
                d="M12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
            ></path>
            <path
                fill={color}
                fillRule="evenodd"
                d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zM2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    </div>
)

export const MinusCircleIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            d="M15 12.75a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z"
        ></path>
        <path
            fill={color}
            fillRule="evenodd"
            d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zM2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const PulsatingLight = ({ size = 25, active = true, showPulse = true }) => {
    let color = active ? 'rgba(0, 255, 0, 1)' : 'rgba(194, 150, 31, 1)'
    const pulseAnimation = `
        @keyframes pulse-status {
            0% {
                r: ${size};
                opacity: 1;
            }
            80% {
                r: ${size * 2};
                opacity: 0;
            }
            100% {
                r: ${size};
                opacity: 0;
            }
        }
    `
    const styleSheet = document.styleSheets[0]
    styleSheet.insertRule(pulseAnimation, styleSheet.cssRules.length)

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${size * 4} ${size * 4}`}
            preserveAspectRatio="xMidYMid meet"
            width={size}
            height={size}
        >
            <circle cx={size * 2} cy={size * 2} r={size * 0.9} fill={color} />
            {active && <circle cx={size * 2} cy={size * 2} r={size} fill={color} className={showPulse && "pulsating-circle"} />}
        </svg>
    )
}

export const CheckmarkIcon = ({ size = 24, color = '#9FADBC', className = '', style = {} }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
        style={style}
    >
        <path
            fill={color}
            d="M16.03 10.03a.75.75 0 10-1.06-1.06l-4.47 4.47-1.47-1.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l5-5z"
        ></path>
        <path
            fill={color}
            fillRule="evenodd"
            d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zM2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const ListIcon = ({ size = 24, color = '#9FADBC', className = '', onClick = null }) => {
    const [colorState, setColorState] = useState(color);

    useEffect(() => {
        setColorState(color);
    }, [color]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            className={className}
            viewBox="0 0 24 24"
            onClick={onClick}
            onMouseEnter={() => setColorState(adjustBrightness(color))}
            onMouseLeave={() => setColorState(color)}
        >
            <path
                fill={colorState}
                fillRule="evenodd"
                d="M10.944 1.25h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.748.749 1.08 1.698 1.238 2.87.153 1.14.153 2.595.153 4.433v4.112c0 1.838 0 3.294-.153 4.433-.158 1.172-.49 2.121-1.238 2.87-.749.748-1.698 1.08-2.87 1.238-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433V9.944c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238 1.14-.153 2.595-.153 4.433-.153zM6.71 2.89c-1.006.135-1.586.389-2.01.812-.422.423-.676 1.003-.811 2.009-.138 1.028-.14 2.382-.14 4.289v4c0 1.907.002 3.262.14 4.29.135 1.005.389 1.585.812 2.008.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.289.14h2c1.907 0 3.262-.002 4.29-.14 1.005-.135 1.585-.389 2.008-.812.423-.423.677-1.003.812-2.009.138-1.027.14-2.382.14-4.289v-4c0-1.907-.002-3.261-.14-4.29-.135-1.005-.389-1.585-.812-2.008-.423-.423-1.003-.677-2.009-.812-1.027-.138-2.382-.14-4.289-.14h-2c-1.907 0-3.261.002-4.29.14zM7.25 8A.75.75 0 018 7.25h8a.75.75 0 010 1.5H8A.75.75 0 017.25 8zm0 4a.75.75 0 01.75-.75h8a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75zm0 4a.75.75 0 01.75-.75h5a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export const MinimizeIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            d="M11.943 1.25h.114c2.309 0 4.118 0 5.53.19 1.444.194 2.584.6 3.479 1.494.895.895 1.3 2.035 1.494 3.48.19 1.411.19 3.22.19 5.529v.114c0 2.309 0 4.118-.19 5.53-.194 1.444-.6 2.584-1.494 3.479-.829.828-1.868 1.238-3.167 1.448-1.277.207-2.883.232-4.898.235a.75.75 0 11-.002-1.5c2.04-.003 3.523-.031 4.66-.216 1.117-.18 1.818-.5 2.346-1.028.57-.57.897-1.34 1.069-2.619.174-1.3.176-3.008.176-5.386s-.002-4.086-.177-5.386c-.171-1.279-.498-2.05-1.068-2.62-.57-.569-1.34-.896-2.619-1.068-1.3-.174-3.008-.176-5.386-.176s-4.087.002-5.387.176c-1.278.172-2.049.5-2.618 1.069-.529.528-.848 1.23-1.028 2.345-.185 1.138-.213 2.621-.217 4.661A.75.75 0 011.25 11c.004-2.016.03-3.622.236-4.898.21-1.3.62-2.338 1.448-3.167.895-.895 2.035-1.3 3.48-1.494 1.411-.19 3.22-.19 5.529-.19z"
        ></path>
        <path
            fill={color}
            d="M16.5 12a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V8.25a.75.75 0 011.5 0v1.94l3.72-3.72a.75.75 0 111.06 1.06l-3.72 3.72h1.94a.75.75 0 01.75.75z"
        ></path>
        <path
            fill={color}
            fillRule="evenodd"
            d="M5.948 13.25c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.726-.456.455-.642 1.022-.726 1.65-.08.594-.08 1.343-.08 2.242v.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.725 1.65.456.456 1.023.642 1.65.726.595.08 1.345.08 2.243.08h.104c.898 0 1.648 0 2.242-.08.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.343.08-2.242v-.104c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08h-.104zm-2.832 1.866c.13-.13.328-.237.79-.3.482-.064 1.13-.066 2.094-.066s1.612.002 2.095.066c.461.063.659.17.789.3.13.13.237.328.3.79.064.482.066 1.13.066 2.094s-.002 1.612-.067 2.095c-.062.461-.169.659-.3.789-.13.13-.327.237-.788.3-.483.064-1.131.066-2.095.066-.964 0-1.612-.002-2.095-.067-.461-.062-.659-.169-.789-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095 0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const MaximizeIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            d="M11.943 1.25h.114c2.309 0 4.118 0 5.53.19 1.444.194 2.584.6 3.479 1.494.895.895 1.3 2.035 1.494 3.48.19 1.411.19 3.22.19 5.529v.114c0 2.309 0 4.118-.19 5.53-.194 1.444-.6 2.584-1.494 3.479-.829.828-1.868 1.238-3.167 1.448-1.277.207-2.883.232-4.898.235a.75.75 0 11-.002-1.5c2.04-.003 3.523-.031 4.66-.216 1.117-.18 1.818-.5 2.346-1.028.57-.57.897-1.34 1.069-2.619.174-1.3.176-3.008.176-5.386s-.002-4.086-.177-5.386c-.171-1.279-.498-2.05-1.068-2.62-.57-.569-1.34-.896-2.619-1.068-1.3-.174-3.008-.176-5.386-.176s-4.087.002-5.387.176c-1.278.172-2.049.5-2.618 1.069-.529.528-.848 1.23-1.028 2.345-.185 1.138-.213 2.621-.217 4.661A.75.75 0 011.25 11c.004-2.016.03-3.622.236-4.898.21-1.3.62-2.338 1.448-3.167.895-.895 2.035-1.3 3.48-1.494 1.411-.19 3.22-.19 5.529-.19z"
        ></path>
        <path
            fill={color}
            d="M12.5 7a.75.75 0 01.75-.75H17a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V8.81l-3.72 3.72a.75.75 0 11-1.06-1.06l3.72-3.72h-1.94A.75.75 0 0112.5 7z"
        ></path>
        <path
            fill={color}
            fillRule="evenodd"
            d="M5.948 13.25c-.898 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.726-.456.455-.642 1.022-.726 1.65-.08.594-.08 1.343-.08 2.242v.104c0 .899 0 1.648.08 2.242.084.628.27 1.195.725 1.65.456.456 1.023.642 1.65.726.595.08 1.345.08 2.243.08h.104c.898 0 1.648 0 2.242-.08.628-.084 1.195-.27 1.65-.726.456-.455.642-1.022.726-1.65.08-.594.08-1.343.08-2.242v-.104c0-.899 0-1.648-.08-2.242-.084-.628-.27-1.195-.725-1.65-.456-.456-1.023-.642-1.65-.726-.595-.08-1.345-.08-2.243-.08h-.104zm-2.832 1.866c.13-.13.328-.237.79-.3.482-.064 1.13-.066 2.094-.066s1.612.002 2.095.066c.461.063.659.17.789.3.13.13.237.328.3.79.064.482.066 1.13.066 2.094s-.002 1.612-.067 2.095c-.062.461-.169.659-.3.789-.13.13-.327.237-.788.3-.483.064-1.131.066-2.095.066-.964 0-1.612-.002-2.095-.067-.461-.062-.659-.169-.789-.3-.13-.13-.237-.327-.3-.788-.064-.483-.066-1.131-.066-2.095 0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const TagIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M11.238 2.799c-.614.081-1.372.255-2.45.504l-1.229.284c-.91.21-1.538.356-2.017.52-.463.159-.725.315-.922.513-.198.197-.354.459-.513.922-.164.479-.31 1.106-.52 2.017l-.284 1.228c-.249 1.079-.423 1.837-.504 2.451-.08.598-.061 1.003.045 1.371.105.368.304.721.688 1.186.395.478.944 1.029 1.727 1.812l1.83 1.83c1.359 1.359 2.326 2.324 3.158 2.958.814.622 1.41.855 2.015.855.606 0 1.201-.233 2.016-.855.831-.634 1.799-1.6 3.158-2.959 1.36-1.36 2.325-2.327 2.96-3.158.62-.815.854-1.41.854-2.016 0-.605-.233-1.2-.855-2.015-.634-.832-1.6-1.8-2.959-3.159l-1.83-1.83c-.782-.782-1.333-1.331-1.81-1.726-.466-.384-.819-.583-1.187-.688-.368-.106-.773-.124-1.37-.045zm-.196-1.487c.717-.095 1.346-.092 1.98.09.635.182 1.17.513 1.728.973.54.446 1.14 1.046 1.891 1.797l1.896 1.896c1.31 1.31 2.348 2.348 3.05 3.27.724.947 1.163 1.859 1.163 2.924 0 1.066-.439 1.978-1.162 2.925-.703.922-1.74 1.96-3.051 3.27l-.08.08c-1.31 1.31-2.348 2.348-3.27 3.05-.947.724-1.86 1.163-2.925 1.163-1.065 0-1.977-.439-2.925-1.162-.921-.703-1.959-1.74-3.27-3.051L4.173 16.64c-.75-.75-1.351-1.351-1.797-1.89-.46-.559-.791-1.094-.973-1.728-.182-.635-.185-1.264-.09-1.981.091-.694.283-1.522.521-2.556l.3-1.303c.2-.863.362-1.567.555-2.128.202-.587.455-1.08.871-1.496.416-.416.91-.67 1.496-.87.561-.194 1.265-.356 2.128-.555l1.303-.3c1.034-.24 1.862-.43 2.556-.522zM9.49 7.995a1.25 1.25 0 10-1.768 1.768 1.25 1.25 0 001.768-1.768zm-2.828-1.06a2.75 2.75 0 113.889 3.889 2.75 2.75 0 01-3.89-3.89zM19.05 10.99a.75.75 0 010 1.06l-6.979 6.98a.75.75 0 01-1.06-1.06l6.978-6.98a.75.75 0 011.061 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const ExcelIcon = ({ size = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 32 32"
    >
        <defs>
            <linearGradient
                id="a"
                x1="4.494"
                x2="13.832"
                y1="-2092.086"
                y2="-2075.914"
                gradientTransform="translate(0 2100)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#18884f"></stop>
                <stop offset="0.5" stopColor="#117e43"></stop>
                <stop offset="1" stopColor="#0b6631"></stop>
            </linearGradient>
        </defs>
        <path
            fill="#185c37"
            d="M19.581 15.35L8.512 13.4v14.409A1.192 1.192 0 009.705 29h19.1A1.192 1.192 0 0030 27.809V22.5z"
        ></path>
        <path
            fill="#21a366"
            d="M19.581 3H9.705a1.192 1.192 0 00-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5z"
        ></path>
        <path fill="#107c41" d="M8.512 9.5h11.069V16H8.512z"></path>
        <path
            d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 001.194-1.191V9.391A1.2 1.2 0 0016.434 8.2z"
            style={{ isolation: "isolate" }}
            opacity="0.1"
        ></path>
        <path
            d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
        ></path>
        <path
            d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
        ></path>
        <path
            d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 001.194-1.191V10.041a1.2 1.2 0 00-1.194-1.191z"
            style={{ isolation: "isolate" }}
            opacity="0.2"
        ></path>
        <path
            fill="url(#a)"
            d="M3.194 8.85h11.938a1.193 1.193 0 011.194 1.191v11.918a1.193 1.193 0 01-1.194 1.191H3.194A1.192 1.192 0 012 21.959V10.041A1.192 1.192 0 013.194 8.85z"
        ></path>
        <path
            fill="#fff"
            d="M5.7 19.873l2.511-3.884-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359 3.84 2.419 3.905h-1.809l-1.45-2.711A2.355 2.355 0 019.2 16.8h-.024a1.688 1.688 0 01-.168.351l-1.493 2.722z"
        ></path>
        <path
            fill="#33c481"
            d="M28.806 3h-9.225v6.5H30V4.191A1.192 1.192 0 0028.806 3z"
        ></path>
        <path fill="#107c41" d="M19.581 16H30v6.5H19.581z"></path>
    </svg>
)

export const SortAscIcon = ({ size = 24, color = '#9FADBC', flipped }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
        strokeMiterlimit="2"
        strokeWidth="0.1"
        clipRule="evenodd"
        viewBox="0 0 64 64"
        transform={flipped ? 'rotate(180)' : ''}
    >
        <g transform="translate(-1216 -320)">
            <path fill="none" d="M0 0H1280V800H0z"></path>
            <g transform="translate(128)">
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M288 216h-32v-4h28v-28h4v32z"
                    transform="rotate(45 409.28 1361.767) scale(.73957 .74269)"
                ></path>
                <path
                    fill='white'
                    fillRule="nonzero"
                    d="M288 216h-32v-4h28v-28h4v32z"
                    transform="rotate(-135 701.74 57.61) scale(.73957 .74269)"
                ></path>
            </g>
        </g>
    </svg>
)

export const JiraIcon = ({ size = 24, onClick = null, title = '', style = {} }) => (
    <div
        title={title}
        onClick={onClick && onClick}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: onClick && 'pointer',
            ...style
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid"
            viewBox="-25.6 -25.6 307.2 307.2"
        >
            <g>
                <defs>
                    <linearGradient
                        id="linearGradient-1"
                        x1="98.031%"
                        x2="58.888%"
                        y1=".161%"
                        y2="40.766%"
                    >
                        <stop offset="18%" stopColor="#0052CC"></stop>
                        <stop offset="100%" stopColor="#2684FF"></stop>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient-2"
                        x1="100.665%"
                        x2="55.402%"
                        y1=".455%"
                        y2="44.727%"
                    >
                        <stop offset="18%" stopColor="#0052CC"></stop>
                        <stop offset="100%" stopColor="#2684FF"></stop>
                    </linearGradient>
                </defs>
                <path
                    fill="#2684FF"
                    d="M244.658 0H121.707a55.502 55.502 0 0055.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z"
                ></path>
                <path
                    fill="url(#linearGradient-1)"
                    d="M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z"
                ></path>
                <path
                    fill="url(#linearGradient-2)"
                    d="M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z"
                ></path>
            </g>
        </svg>
    </div>
)

export const EditIconSquare = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C2 6.28 2 7.12 2 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 22 5.12 22 6.8 22h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.31C20 19.72 20 18.88 20 17.2V13M8 16h1.675c.489 0 .733 0 .963-.055a2 2 0 00.579-.24c.201-.123.374-.296.72-.642L21.5 5.5a2.121 2.121 0 00-3-3l-9.563 9.563c-.346.346-.519.519-.642.72a2 2 0 00-.24.579c-.055.23-.055.474-.055.964V16z"
        ></path>
    </svg>
)

export const EditPencilIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M20.15 7.94L8.28 19.81c-1.06 1.07-4.23 1.56-5 .85-.77-.71-.21-3.88.85-4.95L16 3.84a2.9 2.9 0 014.1 4.1h.05zM21 21h-9"
        ></path>
    </svg>
)

export const SaveIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            cx="12"
            cy="12"
            r="10"
            stroke={color}
            strokeWidth="1.5"
        ></circle>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8.5 12.5l2 2 5-5"
        ></path>
    </svg>
)

export const CheckBoxIcon = ({ size = 24, color = backgroundColor }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 12.611L8.923 17.5 20 6.5"
        ></path>
    </svg>
)

export const MenuDotsIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle cx="5" cy="12" r="2" stroke={color} strokeWidth="1.5"></circle>
        <circle cx="12" cy="12" r="2" stroke={color} strokeWidth="1.5"></circle>
        <circle cx="19" cy="12" r="2" stroke={color} strokeWidth="1.5"></circle>
    </svg>
)

export const MinusIcon = ({ size = 24, color = backgroundColor }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 12h12"
        ></path>
    </svg>
)

export const InfoIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            cx="12"
            cy="12"
            r="10"
            stroke={color}
            strokeWidth="1.5"
        ></circle>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M12 17v-6"
        ></path>
        <circle
            cx="1"
            cy="1"
            r="1"
            fill={color}
            transform="matrix(1 0 0 -1 11 9)"
        ></circle>
    </svg>
)

export const EnterKeyIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M3 14a1 1 0 011-1h12a3 3 0 003-3V6a1 1 0 112 0v4a5 5 0 01-5 5H4a1 1 0 01-1-1z"
            clipRule="evenodd"
        ></path>
        <path
            fill={color}
            fillRule="evenodd"
            d="M3.293 14.707a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 14l3.293 3.293a1 1 0 11-1.414 1.414l-4-4z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const TagHoleCircleIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 12"
    >
        <path
            fill={color}
            fillRule="evenodd"
            d="M6 12A6 6 0 116 0a6 6 0 010 12zm0-2a4 4 0 100-8 4 4 0 000 8z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export const HistoryIcon = ({ size = 24, color = '#9dabbbbd', className = '', onClick = null, activated = false }) => {
    const [colorState, setColorState] = useState(color);

    useEffect(() => {
        setColorState(color);
    }, [color]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 24 24"
            className={className}
            onMouseEnter={() => activated ? setColorState(color) : setColorState(adjustBrightness(color))}
            onMouseLeave={() => activated ? setColorState(adjustBrightness(color)) : setColorState(color)}
            onClick={onClick}
        >
            <path
                stroke={colorState}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 8v4l2.5 2.5"
            ></path>
            <path
                fill={colorState}
                d="m5.604 5.604-.53-.53zM4.338 6.871l-.75.003a.75.75 0 0 0 .746.747zm2.542.762a.75.75 0 1 0 .007-1.5zM5.075 4.321a.75.75 0 1 0-1.5.008zm-1.248 6.464a.75.75 0 1 0-1.486-.204zm15.035-5.647c-3.82-3.82-9.993-3.86-13.788-.064l1.06 1.06c3.2-3.199 8.423-3.18 11.668.064zM5.138 18.862c3.82 3.82 9.993 3.86 13.788.064l-1.06-1.06c-3.2 3.199-8.423 3.18-11.668-.064zm13.788.064c3.795-3.795 3.756-9.968-.064-13.788l-1.06 1.06c3.244 3.245 3.263 8.468.064 11.668zM5.074 5.074 3.807 6.34 4.868 7.4l1.266-1.266zm-.74 2.547 2.546.012.007-1.5-2.545-.012zm.754-.754L5.075 4.32l-1.5.008.013 2.545zM2.34 10.58a9.81 9.81 0 0 0 2.797 8.281l1.06-1.06a8.31 8.31 0 0 1-2.371-7.017z"
            ></path>
        </svg>
    )
}

export const ShrinkArrowsIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m2 22 7-7m0 0H3.143M9 15v5.857M22 2l-7 7m0 0h5.857M15 9V3.143"
        ></path>
    </svg>
)

export const ExpandArrowsIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m9 15-7 7m0 0h5.857M2 22v-5.857M15 9l7-7m0 0h-5.857M22 2v5.857"
        ></path>
    </svg>
)

export const SleepIcon = ({ size = 24, color = '#9FADBC' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M13.5 8h3l-3 3h3M18 2h4l-4 4h4"
        ></path>
        <path
            fill={color}
            d="m21.067 11.857-.642-.388zm-8.924-8.924-.388-.642zM21.25 12A9.25 9.25 0 0 1 12 21.25v1.5c5.937 0 10.75-4.813 10.75-10.75zM12 21.25A9.25 9.25 0 0 1 2.75 12h-1.5c0 5.937 4.813 10.75 10.75 10.75zM2.75 12A9.25 9.25 0 0 1 12 2.75v-1.5C6.063 1.25 1.25 6.063 1.25 12zm12.75 2.25A5.75 5.75 0 0 1 9.75 8.5h-1.5a7.25 7.25 0 0 0 7.25 7.25zm4.925-2.781A5.75 5.75 0 0 1 15.5 14.25v1.5a7.25 7.25 0 0 0 6.21-3.505zM9.75 8.5a5.75 5.75 0 0 1 2.781-4.925l-.776-1.284A7.25 7.25 0 0 0 8.25 8.5zM12 2.75a.38.38 0 0 1-.268-.118.3.3 0 0 1-.082-.155c-.004-.031-.002-.121.105-.186l.776 1.284c.503-.304.665-.861.606-1.299-.062-.455-.42-1.026-1.137-1.026zm9.71 9.495c-.066.107-.156.109-.187.105a.3.3 0 0 1-.155-.082.38.38 0 0 1-.118-.268h1.5c0-.717-.571-1.075-1.026-1.137-.438-.059-.995.103-1.299.606z"
        ></path>
    </svg>
)

// export const CheckmarkIcon